<template>
  <div v-if="pageLoading"
       class="flex justify-start items-center w-full h-screen bg-color-f bg-opacity-50 z-20">
    <a-spin />
  </div>

  <div v-else
       class="live-enter">

    <template v-if="isFinished">
      <div class="flex justify-center items-center text-32 font-medium text-center">直播已结束</div>
    </template>

    <div v-else
         class="live-enter__info">

      <p class="mb-80 text-24 font-medium text-center">进入直播间</p>

      <div class="flex justify-between items-center">
        <div style="max-width: 466px;">
          <div class="flex text-color-666">
            <span class="inline-block text-right"
                  style="min-width: 80px;">直播主题：</span>
            <div class="text-color-333">{{live.title}}</div>
          </div>

          <p class="my-24 text-color-666">
            <span class="inline-block text-right"
                  style="min-width: 80px;">直播状态：</span>

            <span v-if="currentTime < live.startTime"
                  class="text-color-333">距直播开始还剩
              <a-statistic-countdown :value="live.startTime"
                                     format="H 时 m 分 s 秒" />
            </span>

            <span v-else
                  class=" text-success">直播已开始，请尽快进入直播间</span>

          </p>

          <div v-if="live.summary"
               class="p-16 text-color-333 bg-color-f bg-opacity-50 overflow-y-auto"
               style="width: 466px;  border-radius: 8px">
            {{live.summary}}
          </div>
        </div>

        <div class="flex flex-col justify-center">
          <div class="mx-auto text-14 text-color-333"
               style="min-height: 180px">
            <Qrcode :width="180"
                    :height="180"
                    :value="authUrl"
                    :dotsOptions="{type: 'square', color: '#000'}"
                    :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                    imgclass="download-img" />
          </div>
          <p class="mt-16 text-14 text-color-333">用微信或企业微信扫码进入直播间</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import dayjs from "dayjs";
import { Statistic } from "ant-design-vue";
import Qrcode from "qrcode-vue3";
import liveApi from "@/service/api/live";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

import { browserEnv } from "@/global";

export default defineComponent({
  name: "LiveOpenClassEnter",

  components: {
    AStatisticCountdown: Statistic.Countdown,

    Qrcode,
  },
  setup() {
    const currentTime = dayjs().valueOf();
    const startedTime = ref();
    const pageLoading = ref(true);

    const live = reactive({});

    const route = new useRoute();
    const router = new useRouter();
    const authUrl = ref("");

    const isMobile = computed(() => {
      return _.includes(browserEnv, "Mobile");
    });

    // 直播结束异常捕获
    const isFinished = ref(false);

    const getLiveInfo = async function () {
      if (!route.query.id) {
        router.push({ name: "404", query: { desc: "未找到该直播间" } });
        return;
      }

      try {
        const res = await liveApi.getEntryInfo({ roomId: route.query.id });
        _.assign(live, res);
        authUrl.value = getAuthUrl();
      } catch (err) {
        if (err.code === "40390005") {
          router.push({
            name: "404",
            query: { desc: "未找到该直播间", backRoute: "liveOpenClass_index" },
          });
          return;
        }
        if (err.code === "40390047") {
          isFinished.value = true;
          return;
        }
      } finally {
        pageLoading.value = false;
      }

      getAuthRes();
    };

    const getAuthUrl = function () {
      return (
        "https://" +
        window.location.host +
        "/customer-h5/live/auth?ticket=" +
        live.entryTicket +
        "&corpId=" +
        live.corpId
      );
    };

    // 轮询扫码结果

    const timer = ref();
    const getAuthRes = function () {
      let count = 0;
      if (count > 10) {
        clearInterval(timer.value);
      }
      timer.value = setInterval(async () => {
        const res = await liveApi.getEntryAuthRes({
          corpId: live.corpId,
          ticket: live.entryTicket,
        });
        count++;

        if (res.status === "authed") {
          window.location.href = res.entryUrl;

          clearInterval(timer.value);
        }
      }, 3000);
    };

    if (!isMobile.value) {
      getLiveInfo();
    } else {
      window.location.href = `https://${window.location.host}/customer-h5/common/blankPage?errorText=请在电脑端打开`;
    }

    return {
      isMobile,
      pageLoading,
      currentTime,
      startedTime,

      isFinished,

      live,
      authUrl,
    };
  },
});
</script>
<style lang='less' scoped>
.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
  background-color: #fff;

  z-index: 20;
}

.live-enter {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  min-width: 1440px;
  min-height: 772px;

  z-index: 1;
  zoom: 1;
  background-image: url("../../../assets/bg-liveEnter.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;

  &__info {
    margin-top: 10%;
    width: 55%;
    min-width: 800px;
    height: 40%;

    :deep(.ant-statistic) {
      display: inline-block;
      &-content {
        font-size: 14px;
      }
      &-content-value {
        font-size: 14px;
        color: @color-danger;
      }
    }
  }
}
</style>